<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="dashboard.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/staatsbuergerschaft' }">
        <app-i18n code="entities.staatsbuergerschaft.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.staatsbuergerschaft.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.staatsbuergerschaft.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.staatsbuergerschaft.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.staatsbuergerschaft.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="initLoading"
        v-loading="initLoading"
      ></div>

      <app-staatsbuergerschaft-form
        :isEditing="isEditing"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="!initLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StaatsbuergerschaftForm from '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-form.vue';
import { routerAsync } from '@/app-module';

export default {
  name: 'app-staatsbuergerschaft-form-page',

  props: ['id'],

  components: {
    'app-staatsbuergerschaft-form': StaatsbuergerschaftForm,
  },

  computed: {
    ...mapGetters({
      record: 'staatsbuergerschaft/form/record',
      initLoading: 'staatsbuergerschaft/form/initLoading',
      saveLoading: 'staatsbuergerschaft/form/saveLoading',
    }),

    isEditing() {
      return Boolean(this.id);
    },
  },

  async created() {
    await this.doInit(this.id);
  },

  methods: {
    ...mapActions({
      doInit: 'staatsbuergerschaft/form/doInit',
      doUpdate: 'staatsbuergerschaft/form/doUpdate',
      doCreate: 'staatsbuergerschaft/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/staatsbuergerschaft');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
</script>

<style></style>
